import { defineStore } from 'pinia'
import api from '../api'
import dataLayer from '../dataLayer'
import { mapRawTracks } from '../mapping'
import { listId, editionId } from '../settings'
import { cdnImageUrl } from '@dpgradio/creative'

const MAX_RANDOM_TRACKS = 5

const letters = [
  ...Array.from(Array(26))
    .map((e, i) => i + 65)
    .map((x) => [String.fromCharCode(x), []]),
  ['#', []],
]

export const useChartStore = defineStore('chart', {
  state: () => {
    return {
      listId: undefined,
      editionId: undefined,

      listName: undefined,
      editionName: undefined,
      description: undefined,

      image: undefined,

      shareImages: {
        facebook: undefined,
        instagram: undefined,
      },

      constraints: {
        minVotes: 0,
        maxVotes: 0,
        maxVotesPerArtist: 0,
      },

      startDate: undefined,
      endDate: undefined,

      trackSuggestions: [],
      tracksByLetter: Object.fromEntries(letters),

      totalTrackOptionsCount: undefined,
    }
  },
  getters: {
    name: (state) => state.listName,
  },
  actions: {
    async fetchChartDetails() {
      this.listId = listId
      this.editionId = editionId

      const result = await api.tracks(this.listId, this.editionId)

      this.listName = result.list_name
      this.editionName = result.edition_name
      this.description = result.description

      this.image = cdnImageUrl(result.image)

      this.constraints.minVotes = result.minimal_votes_nr
      this.constraints.maxVotes = result.allowed_votes_nr
      this.constraints.maxVotesPerArtist = result.max_votes_per_artist

      this.totalTrackOptionsCount = result.count

      this.shareImages.facebook = cdnImageUrl(result.share_facebook, 'w1200')
      this.shareImages.instagram = cdnImageUrl(result.share_instagram, 'w1200')

      this.trackSuggestions = mapRawTracks(result.tracks.slice(0, MAX_RANDOM_TRACKS))

      if (result.event?.timespans?.length) {
        this.startDate = new Date(result.event?.timespans[0]?.start)
        this.endDate = new Date(result.event?.timespans[result.event?.timespans?.length - 1]?.stop)
      }
    },
    async fetchRandomTracks() {
      const result = await api.tracks(this.listId, this.editionId)

      this.trackSuggestions = mapRawTracks(result.tracks.slice(0, MAX_RANDOM_TRACKS))

      dataLayer.event('selection', 'random_refresh')
    },
    async fetchTracksByLetter(letter, options = { nextPage: false }) {
      const page = options.nextPage ? Math.ceil(this.tracksByLetter[letter].length / 50) + 1 : 1
      const result = await api.tracksByLetter(this.listId, this.editionId, letter, { page })

      this.tracksByLetter[letter] = [...this.tracksByLetter[letter], ...mapRawTracks(result.tracks)]

      dataLayer.event('selection', 'tracks_by_letter', { letter })

      return {
        totalLoaded: this.tracksByLetter[letter].length,
        totalCount: result.count,
        nextAvailable: this.tracksByLetter[letter].length < result.count,
      }
    },
  },
})
